import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"product-card text-center",class:{ 'reduced-width': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"elevation":"0","rounded":"xl"}},[_c(VCardTitle,{staticClass:"product-title text-center d-block black--text"},[_vm._t("title")],2),_c(VCardText,{staticClass:"text-h4 font-weight-black primary-color--text pb-0"},[_c('div',{staticClass:"d-flex flex-column align-center"},[_vm._v(" "+_vm._s(_vm.price)+" ")])]),_c(VCardText,{staticClass:"black--text text-body-1"},[_vm._t("description")],2),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"text-uppercase px-10",attrs:{"rounded":"","color":"success","disabled":!_vm.canBuy,"loading":_vm.isAddingToCart},on:{"click":_vm.addToCart}},[_vm._v(" Add to cart ")])],1),_c(VCardText,{staticClass:"pt-0 text-center"},[_c(VIcon,{staticClass:"mr-1"},[_vm._v("$vuetify.icons.secure")]),_c(VIcon,{staticClass:"mr-1",attrs:{"large":""}},[_vm._v("$vuetify.icons.mastercard")]),_c(VIcon,{staticClass:"mr-1",attrs:{"large":""}},[_vm._v("$vuetify.icons.amex")]),_c(VIcon,{staticClass:"mr-1",attrs:{"large":""}},[_vm._v("$vuetify.icons.visa")]),_c(VIcon,{attrs:{"large":""}},[_vm._v("$vuetify.icons.paypal")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }