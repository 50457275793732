<template>
    <div
        class="primary-color--text text-h5 text-center font-weight-medium pt-4 pb-6"
    >
        <slot></slot>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class ProductBanner extends Vue {}
</script>
