<template>
    <v-card
        elevation="0"
        rounded="xl"
        class="product-card text-center"
        :class="{ 'reduced-width': $vuetify.breakpoint.mdAndUp }"
    >
        <v-card-title class="product-title text-center d-block black--text">
            <slot name="title"></slot>
        </v-card-title>
        <v-card-text class="text-h4 font-weight-black primary-color--text pb-0">
            <div class="d-flex flex-column align-center">
                {{ price }}
            </div>
        </v-card-text>
        <v-card-text class="black--text text-body-1">
            <slot name="description"></slot>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn
                rounded
                color="success"
                class="text-uppercase px-10"
                :disabled="!canBuy"
                :loading="isAddingToCart"
                @click="addToCart"
            >
                Add to cart
            </v-btn>
        </v-card-actions>
        <v-card-text class="pt-0 text-center">
            <v-icon class="mr-1">$vuetify.icons.secure</v-icon>
            <v-icon large class="mr-1">$vuetify.icons.mastercard</v-icon>
            <v-icon large class="mr-1">$vuetify.icons.amex</v-icon>
            <v-icon large class="mr-1">$vuetify.icons.visa</v-icon>
            <v-icon large>$vuetify.icons.paypal</v-icon>
        </v-card-text>
    </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

import { Endpoint } from '@/mixins';

type CreditPrice = {
    price: number;
};

const ProductCardProps = Vue.extend({
    name: 'ProductCard',
    props: {
        id: {
            type: [Number, String],
            default() {
                return 0;
            }
        }
    }
});

@Component
export default class ProductCard extends mixins(ProductCardProps, Endpoint) {
    isAddingToCart = false;

    endpoint = '/credits/purchase';

    priceLoader = '. . .';

    price = this.priceLoader;

    get sourceUrl() {
        return [this.endpoint, this.id].join('/');
    }

    get canBuy() {
        return Boolean(!this.isLoading && this.price !== this.priceLoader);
    }

    onMounted() {
        if (this.id) {
            this.load();
        }
    }

    onData(data: CreditPrice) {
        this.price = `$${data.price}`;
    }

    addToCart() {
        this.setAddingToCart();

        this.$http
            .post(this.sourceUrl)
            .then(({ data: actionData }) => {
                if (actionData.data?.redirect) {
                    this.redirect(actionData.data?.redirect);
                }
            })
            .finally(this.setAddingToCart.bind(this, false));
    }

    setAddingToCart(isAdding = true) {
        this.isAddingToCart = isAdding;
    }

    redirect(url: string) {
        window.location.href = url;
    }
}
</script>

<style lang="scss" scoped>
.product-card::v-deep {
    max-width: 100%;

    &.reduced-width {
        max-width: 45ch;
    }

    .product-title {
        word-break: keep-all;
        font-size: 1.5rem;
    }

    .price-loader {
        width: 80px;
    }
}
</style>
