<template>
    <div class="d-flex flex-column align-center">
        <product-banner>Add More Campaigns</product-banner>
        <product-card id="1002">
            <template #title>1 Financial Distribution</template>
            <template #description>
                Get your content on top finance sites like Yahoo! Finance and
                Nasdaq. As some of the biggest and most influential sites they
                have very high visibility in Google News, Google and other
                networks. Financial & investment related content as well as
                company or corporate announcements are preferred, but a variety
                of content can be accepted. Please note the rules for Financial
                Distribution content are very strict, so working with our
                authoring team is recommended.
            </template>
        </product-card>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { ProductBanner, ProductCard } from '@/components/ProductCard';
@Component({
    components: {
        ProductBanner,
        ProductCard
    }
})
export default class Financial extends Vue {
    mounted() {
        this.$emit('no-appointment');
    }
}
</script>
