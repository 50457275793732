import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"product-action px-3 pt-1 pb-2",class:{ 'reduced-width': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"elevation":"0","rounded":"lg"}},[_c(VCardTitle,{staticClass:"d-block action-title text-body-1 font-weight-bold pb-3"},[_vm._t("title")],2),_c(VCardText,{staticClass:"flex-grow-1 text-body-2 pb-1"},[_vm._t("description")],2),_c(VCardActions,{staticClass:"ml-2 pb-4"},[_c('a',{staticClass:"font-weight-bold text-body-2",attrs:{"href":"link","target":"_blank"}},[_vm._t("button"),_c(VIcon,{attrs:{"color":"primary","x-small":"","right":""}},[_vm._v("arrow-right")])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }